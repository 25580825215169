.heroH1{
    color: white;
    margin-top: 250px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .heroH1{
        margin: 130px 10px 0px 10px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .heroH1{
        margin: 260px 5px 0px 5px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .heroH1{
        margin: 280px 0px 0px 0px;
    }
}


@media screen and (min-width: 1201px) and (max-width:1450px) { /* XLarge Devices */  
    .heroH1{
        margin: 270px 10px 0px 10px;
    }
}

@media screen and (min-width: 1451px) and (max-width:1900px) { /* XXLarge Devices */  
    .heroH1{
        margin: 290px 10px 0px 10px;
    }
}

@media screen and (min-width: 1901px) { /* XXXLarge Devices */  
    .heroH1{
        margin: 350px 0px 0px 380px;
    }
}