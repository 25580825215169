.educAffImg{
    margin: 0px 0px 0px 50px;
    max-width: 90%;
    height: auto;
}

.title{
    text-shadow: 0 1px 0 #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #000000;   
}

.description{
    object-fit: contain;
    font-size: 15px;
    line-height: 1.54;
    text-align: justify;
    color: #4a4a4a;
    padding: 0px 60px 0px 40px;
}

.description li{
    padding: 5px 0px 0px 0px;
    list-style-type: none;
}

.description li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

.affilRow{
    margin-bottom: 30px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .educAffImg{
        margin: 80px 0px 0px 20px;
        max-width: 100%;
    }
    
    .title{
        font-size: 12px;
        font-weight: 600;
        margin: 0px 0px 0px 45px;
    }
    
    .description{
        object-fit: contain;
        font-size: 10px;
        line-height: 1.54;
        text-align: justify;
        color: #4a4a4a;
        padding: 0px 10px 0px 0px;
        width: 240px;
    }
    
    .description li{
        padding: 5px 0px 0px 0px;
        list-style-type: none;
    }
    
    .description li:before {
        content: '\2022';
        color:rgba(121, 219, 111, 0.64);
        padding-right: 0.5em;
    }
    
    .affilRow{
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .educAffImg{
        margin: 40px 0px 0px 20px;
        max-width: 100%;
    }
    
    .title{
        font-size: 18px;  
    }
    
    .description{
        width: 500px;
        font-size: 13px;
        padding: 0px 30px 0px 10px;
    }

    .affilRow{
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .educAffImg{
        margin: 20px 0px 0px 60px;
        max-width: 90%;
    }
    
    .title{
        font-size: 20px;  
    }
    
    .description{
        width: 600px;
        font-size: 15px;
        padding: 0px 80px 0px 30px;
    }

    .affilRow{
        margin-bottom: 20px;
    }
}
