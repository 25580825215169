.description{
    font-size: 20px;
    line-height: 1.4;
    text-align: justify;
    color: #4a4a4a;
    padding: 0px 10px 0px 60px;
    position: relative;
    top:90px;
}

.homeFeaturesImg{
    max-width: 100%; 
    height: auto; 
}

.buttonServices{
    text-align: center;
    position: relative;
    bottom: 400px;
    left: 15px;
}

.buttonBlog{
    text-align: center;
    position: relative;
    bottom: 400px;
    left: 50px;
}

.buttonContact{
    text-align: center;
    position: absolute;
    bottom: 170px;
    left: 430px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .description{
        font-size: 14px;
        line-height: 1.4;
        position: relative;
        top:0px;
        padding: 0px 10px 0px 10px;
    }

    .buttonServices{
        font-size: 8.5px;
        bottom: 170px;
        left: 2px;
        padding: 3px 3px 3px 3px;
    }
    .buttonServices:hover{
        font-size: 9px;
    }
    
    .buttonBlog{
        font-size: 8.5px;
        position: absolute;
        bottom: 150px;
        left: 200px;
        padding: 3px 3px 3px 3px;
        margin: 0px 20px 0px 35px;
    }
    .buttonBlog:hover{
        font-size: 9px;
    }
    
    .buttonContact{
        font-size: 8.5px;
        bottom: 40px;
        left: 210px;
        padding: 3px 3px 3px 3px;
        margin: 0px 25px 0px 35px;
    }
    .buttonContact:hover{
        font-size: 9px;
    }
}
    

  @media screen and (min-width: 768px) and (max-width:991px) { /* Medium Devices */
    .description{
        font-size: 16px;
        line-height: 1.4;
        position: relative;
        top: 20px;
        padding: 0px 5px 0px 10px;
    }

    .homeFeaturesImg{
        max-width: 100%; 
    }
    
    .buttonServices{
        font-size: 11.5px;
        bottom: 250px;
        left: 1px;
        padding: 10px 5px 10px 5px;
    }
    .buttonServices:hover{
        font-size: 12px;
    }
    
    .buttonBlog{
        font-size: 11.5px;
        position: absolute;
        bottom: 230px;
        left: 220px;
        padding: 5px 5px 5px 5px;
        margin: 0px 50px 0px 50px;
    }
    .buttonBlog:hover{
        font-size: 12px;
    }
    
    .buttonContact{
        font-size: 11.5px;
        bottom: 100px;
        left: 265px;
        padding: 10px 5px 10px 5px;
    }
    .buttonContact:hover{
        font-size: 12px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /*Large Devices */
    .description{
        font-size: 19px;
        line-height: 1.4;
        position: relative;
        top: 50px;
        padding: 0px 10px 0px 56px;
    }

    .homeFeaturesImg{
        max-width: 100%; 
    }
    
    .buttonServices{
        font-size: 16px;
        bottom: 330px;
        left: 5px;
        padding: 10px 5px 10px 5px;
    }
    .buttonServices:hover{
        font-size: 16.5px;
    }
    
    .buttonBlog{
        font-size: 16px;
        position: absolute;
        bottom: 320px;
        left: 307px;
        padding: 5px 5px 5px 5px;
    }
    .buttonBlog:hover{
        font-size: 16.5px;
    }
    
    .buttonContact{
        font-size: 16px;
        bottom: 130px;
        left: 355px;
        padding: 10px 5px 10px 5px;
    }
    .buttonContact:hover{
        font-size: 16.5px;
    } 
}

