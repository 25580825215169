.bodyHeader{
    font-size: 35px;
    padding: 45px 0px 10px 45px;
    color: #000000;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.line {
    width: 250px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #fde582, #ccdba8 31%, #99d1c8 69%, #0ec0fd);
    border-image-slice: 1;
    margin-left: 45px;
    margin-bottom: 60px;  
}

@media screen and (max-width: 767px) { /* Small Devices */
    .bodyHeader{
        font-size: 25px;
        padding: 0px 0px 7px 0px;
    }
    
    .line {
        width: 200px;
        margin-left: 0px;
        margin-bottom: 30px;  
    } 
}

@media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .bodyHeader{
        font-size: 32px;
        padding: 50px 0px 10px 0px;
    }
    
    .line {
        width: 250px;
        margin-left: 0px;
        margin-bottom: 50px;  
    } 
}
