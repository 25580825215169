.container{
    border: solid 2px #0fc0fd;
    margin-bottom: 80px;
}

.colLeft{
    background-color: rgba(48, 176, 219, 0.4);
}

.iconLogo{
    width: 130px;
    max-width: 100%;
    height: auto;
    margin: 80px 0px 50px 220px;
}

.contactInfo{
    object-fit: contain;
    font-size: 17px;
    line-height: 1.4;
    text-align: center;
    color: #000000;
}

.error{
    color: red;
}

.success{
    color: green;
}

.line{
    width: 250px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #fde582, #ccdba8 31%, #99d1c8 69%, #0ec0fd);
    border-image-slice: 1;
    margin: 25px 0px 25px 140px;  
}

.form{
    margin: 30px 60px 30px 60px;
}

.inputText{
    font-size: 17px;
    color: #4a4a4a;
}

.label{
    font-size: 17px;
}

.button{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color: rgba(255, 255, 255, 0.98);
    color: #6fdb70;
    font-size: 17px;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 0px 335px;
}

.button:hover{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color:  #6fdb70;
    color:#ffffff;
    font-size: 17.5px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .container{
        margin-bottom: 30px;
    }
    
    .iconLogo{
        max-width: 60%;
        margin: 60px 0px 50px 40px;
    }
    
    .contactInfo{
        font-size: 12px;
        line-height: 1.5;
    }
    
    .line{
        width: 120px;
        margin: 20px 0px 25px 20px;  
    }
    
    .form{
        margin: 10px 10px 10px 10px;
    }
    
    .inputText{
        font-size: 10px;
    }
    
    .label{
        font-size: 12px;
    }    

    .button{
        border: solid 2px #6fdb70;
        font-size: 12px;
        padding: 5px 5px 5px 5px;
        margin: 0px 0px 0px 70px;
    }
    
    .button:hover{
        border: solid 2px #6fdb70;
        font-size: 12.5px;
    }
}

@media screen and (min-width: 576px) and (max-width:767px) { /* Small Devices */
    .container{
        margin-bottom: 40px;
    }
    
    .iconLogo{
        max-width: 60%;
        margin: 60px 0px 50px 60px;
    }
    
    .contactInfo{
        font-size: 14px;
        line-height: 1.5;
    }
    
    .line{
        width: 150px;
        margin: 20px 0px 25px 45px;  
    }
    
    .form{
        margin: 10px 10px 10px 10px;
    }
    
    .inputText{
        font-size: 14px;
    }
    
    .label{
        font-size: 14px;
    }    

    .button{
        font-size: 14px;
        margin: 0px 0px 0px 140px;
    }
    
    .button:hover{
        font-size: 14.5px;
    } 
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .container{
        margin-bottom: 60px;
    }

    .iconLogo{
        margin: 80px 0px 50px 110px;
    }
    
    .line{
        width: 230px;
        margin: 25px 0px 25px 50px;  
    }
    
    .form{
        margin: 20px 30px 20px 30px;
    }
    
    .button{
        margin: 0px 0px 0px 180px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .iconLogo{
        margin: 80px 0px 50px 180px;
    }
    
    .line{
        margin: 25px 0px 25px 100px;  
    }

    .button{
        margin: 0px 0px 0px 240px;
    }
}
