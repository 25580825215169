.container{
    margin-bottom: 100px;  
}

.row1{
    margin: 0px 0px 0px 100px;
}

.row2{
    margin: 0px 0px 0px 100px;
}

.curaLogo{
    max-width: 40%; 
    height: auto; 
}

.ingredionLogo{
    max-width: 50%; 
    height: auto; 
    margin: 0px 0px 0px 0px;
}

.igyLogo{
    max-width: 35%; 
    height: auto; 
}

.purFoodsLogo{
    max-width: 50%; 
    height: auto; 
    margin: 50px 0px 0px 0px;
}

.dianaLogo{
    max-width: 70%; 
    height: auto; 
    margin: 50px 0px 0px 0px;
}

.gripLogo{
    max-width: 100%; 
    height: auto; 
    margin: 40px 0px 0px 0px;
}

.dianaText{
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #4a4a4a;
    position: absolute;
    left: 70px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .container{
        margin-bottom: 50px;  
    }
    
    .row1{
        margin: 0px 0px 0px 0px;
    }
    
    .row2{
        margin: 0px 0px 0px 0px;
    }
    
    .curaLogo{
        margin: 0px 0px 0px 65px;
    }
    
    .ingredionLogo{
        margin: 20px 0px 0px 57px;
    }
    
    .igyLogo{
        margin: 20px 0px 0px 75px;
    }
    
    .purFoodsLogo{
        margin: 20px 0px 0px 72px;
    }
    
    .dianaLogo{
        margin: 20px 0px 0px 60px;
    }
    
    .gripLogo{
        margin: 30px 0px 0px 70px;
    }
    
    .dianaText{
        font-size: 12px;
        text-align: left;
        left: 120px;
    }    
}  

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium Devices */
    .container{
        margin-bottom: 60px;  
    }
    
    .row1{
        margin: 0px 0px 0px 70px;
    }
    
    .row2{
        margin: 0px 0px 0px 70px;
    }
    
    .curaLogo{
        max-width: 60%; 
    }
    
    .ingredionLogo{
        max-width: 70%; 
    }
    
    .igyLogo{
        max-width: 55%; 
    }
    
    .purFoodsLogo{
        max-width: 70%; 
    }
    
    .dianaLogo{
        max-width: 90%; 
    }
    
    .gripLogo{
        max-width: 100%; 
    }
    
    .dianaText{
        font-size: 13px;
        padding: 0px 10px 0px 10px;
        left: 50px;
    } 
}

@media screen and (min-width: 992px) and (max-width:1200px) { /*Large Devices */
    .container{
        margin-bottom: 80px;  
    }
    
    .row1{
        margin: 0px 0px 0px 70px;
    }
    
    .row2{
        margin: 0px 0px 0px 70px;
    }
    
    .curaLogo{
        max-width: 60%; 
    }
    
    .ingredionLogo{
        max-width: 70%; 
    }
    
    .igyLogo{
        max-width: 55%; 
    }
    
    .purFoodsLogo{
        max-width: 70%; 
    }
    
    .dianaLogo{
        max-width: 90%; 
    }
    
    .gripLogo{
        max-width: 100%; 
    }
    
    .dianaText{
        font-size: 13px;
        padding: 0px 10px 0px 10px;
        left: 90px;
    }     
}

