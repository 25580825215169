.heroH1{
    color: black;
    margin-top: 280px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .heroH1{
        margin: 75px 1px 0px 1px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .heroH1{
        margin: 220px 10px 0px 13px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .heroH1{
        margin: 230px 0px 0px 0px;
    }
}