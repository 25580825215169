.primaryFooter{
    background-color: rgba(121, 219, 111, 0.64);
    margin:0px auto;
    padding: 10px 0px 0px 0px;
}

.footerMenu{
    list-style: none;
    font-size: 16px;  
    padding: 0px; 
}

.footerMenu li a{
    color: black;
}

.h5Title {
    color: white;  
    font-size: 16px;
}

.sortUp{
    color: white;
    margin: 0px 0px 0px 100px;
}

.logoImg{
    width: 239px;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 0px 0px 10px 0px;
}

.address{
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: #000000;
    margin: 0px 0px 30px 0px;
}

.cta {   
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  color: #000000;
  margin: 0px 130px 25px 0px; 
}

.linkedinIcon{
    color: #ffffff;
}

.emailIcon{
    color: #ffffff;
}

.connectSection{
    margin: 0px 0px 0px 0px;
}

.secondaryFooter{
    color: #000000;
    text-align: center;
    background-color: #ffffff;
    margin: 20px 0px 0px 0px;
    font-size: 13px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .primaryFooter{
        padding: 0px 0px 0px 0px;
    }
    .footerMenu{
        font-size: 11px;  
    }

    .h5Title { 
        font-size: 11px;
    }

    .sortUp{
        margin: 0px 0px 0px 70px;
    }

    .logoImg{
        margin: 0px 0px 0px 0px;
        padding: 0px 20px 0px 0px;
    }
    
    .address{
        font-size: 11px;
        line-height: 1;
    }
    
    .cta {   
      font-size: 11px;
      margin: 0px 50px 5px 0px; 
    }

    .connectSection{
        margin: 0px 0px 15px 30px;
    }
    
    .secondaryFooter{
        font-size: 8px;
        margin: 15px 0px 0px 0px;
        line-height: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .footerMenu{
        font-size: 15px;  
    }

    .h5Title { 
        font-size: 15px;
    }

    .sortUp{
        margin: 0px 0px 0px 100px;
    }
    
    .address{
        font-size: 13px;
    }
    
    .cta {   
      font-size: 15px;
      margin: 20px 20px 25px 0px; 
      line-height: 1.2;
    }
    
    .secondaryFooter{
        font-size: 10px;
    }
  }



