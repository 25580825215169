.heroH1{
    margin-top: 190px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .heroH1{
        margin: 90px 5px 0px 5px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .heroH1{
        margin: 190px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .heroH1{
        margin-top: 200px;
    }
}
