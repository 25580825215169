.carousel{
    margin: 0px 0px 80px 0px; 
    max-width: 100%;
    height: auto;
}

.backgrImg{
    max-width: 100%;
    height: auto;
}

.caption{
    width: 800px;
    height: 160px;
    border-radius: 13px;
    background-color: rgba(229, 237, 228, 0.8);
    color: black;
    font-size: 18px;
    margin: 0px 0px 40px 0px; 
}

.controls {
    color:  rgba(121, 219, 111, 0.8);
}

@media screen and (max-width: 767px) { /* Small Devices */
    .carousel{
        margin: 0px 0px 30px 0px; 
    }
    
    .caption{
        width: 150px;
        height: 100px;
        font-size: 10px;
        margin: 0px 0px 0px 0px; 
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .carousel{
        margin: 0px 10px 60px 0px; 
    }
    
    .caption{
        width: 500px;
        height: 170px;
        font-size: 12px;
        margin: 0px 0px 20px 0px; 
    }
}

@media screen and (min-width: 992px) and (max-width:1400px) { /* Large to XLarge Devices */  
    .carousel{
        margin: 0px 0px 60px 0px; 
    }
    
    .caption{
        width: 700px;
        height: 160px;
        font-size: 18px;
        margin: 0px 0px 40px 0px; 
    }
}
