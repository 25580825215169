.navbarToggler{
  color: #6fdb70;
}

.logo{
  width: 300px;
  max-width: 100%;
  height: auto;
}

.navItem{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin: 15px 10px 0px 10px;
}

.navItem:hover{
  color: #6fdb70;
}

.navSubItem{
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #000000;  
}

.navSubItem:hover{
  color: #6fdb70;
}

.navBar{ 
  background-color: white !important;
  opacity: 0.80; 
  width: 100%;
  padding-top: 20px;
}

.container{
  margin-left: 22%;
}

  @media screen and (max-width: 767px) { /* Small Devices */
    .logo{
      max-width: 50%;
      height: auto;
      margin: 0px 0px 0px 90px;
  }
    .navItem{
      font-size: 9px;
      margin: 0px 0px 0px 0px;
    }
  
    .navSubItem{
      font-size: 8px;
    }

    .navBar{
      padding-top: 0px;
    }

    .container{
      margin-left: 0%;
    }
  }

  @media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .navItem{
      font-size: 11px;
      margin: 10px 10px 0px 10px;
    }
  
    .navSubItem{
      font-size: 10px;
    }

    .navBar{
      padding-top: 12px;
    }

    .container{
      margin-left: 2%;
    }
  }

  @media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
  .navItem{
      font-size: 13px;
      margin: 15px 10px 0px 10px;
    }
  
    .navSubItem{
      font-size: 11px;
    }

    .navBar{
      padding-top: 15px;
    }

    .container{
      margin-left: 12%;
    }
  }

  @media screen and (min-width: 1201px) and (max-width:1450px) { /* XLarge Devices */  
    .navItem{
        font-size: 14px;
        margin: 15px 10px 0px 10px;
      }
    
      .navSubItem{
        font-size: 12px;
      }
  
      .navBar{
        padding-top: 15px;
      }
    }

    @media screen and (min-width: 1451px) and (max-width:1900px) { /* XXLarge Devices */  
        .navBar{
          padding-top: 15px;
        }

        .container{
          margin-left: 25%;
        }
      }

      @media screen and (min-width: 1901px) { /* XXXLarge Devices */  
        .navBar{
          padding-top: 15px;
        }

        .container{
          margin-left: 35%;
        }
      }