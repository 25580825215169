.img{
    margin: 120px 0px 0px 0px;
    max-width: 100%;
    height: auto;
    width: 550px;
}

.descripTitle{
    object-fit: contain;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.32;
    text-align: center;
    color: #4a4a4a;
}

.description{
    padding: 0px 0px 0px 30px;
    object-fit: contain;
    font-size: 20px;
    line-height: 1.25;
    text-align: left;
    color: #4a4a4a;
    margin: 0px 0px 60px 0px;
}

.description li{
    padding: 20px 0px 0px 0px;
    list-style-type: none;
}

.description li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

.capTitle{
    object-fit: contain;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.32;
    text-align: center;
    color: #4a4a4a;
    margin: 50px 0px 20px 0px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .img{
        margin: 0px 0px 30px 35px;
        max-width: 80%;
    }
    
    .descripTitle{
        font-size: 14px;
        line-height: 0;
    }
    
    .description{
        padding: 0px 10px 0px 10px;
        font-size: 12px;
        margin: 0px 0px 40px 0px;
    }
    
    .description li{
    padding: 15px 0px 0px 0px;
    }
    
    .capTitle{
        object-fit: contain;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.32;
        margin: 30px 0px 15px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .img{
        margin: 60px 0px 0px 0px;
        max-width: 100%;
    }
    
    .descripTitle{
        font-size: 18px;
        line-height: 0;
    }
    
    .description{
        padding: 0px 0px 0px 10px;
        font-size: 14px;
    }
    
    .description li{
    padding: 15px 0px 0px 0px;
    }
    
    .capTitle{
        object-fit: contain;
        font-size: 18px;
        margin: 30px 0px 15px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .img{
        margin: 40px 0px 0px 0px;
        max-width: 100%;
    }
    
    .descripTitle{
        font-size: 20px;
        line-height:0;
    }
    
    .description{
        padding: 0px 0px 10px 10px;
        font-size: 17px;
    }
    
    .description li{
    padding: 15px 0px 0px 0px;
    }
    
    .capTitle{
        object-fit: contain;
        font-size: 20px;
        margin: 30px 0px 15px 0px;
    }
}

