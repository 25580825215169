.description{
    padding: 0px 100px 20px 100px;
    font-size: 20px;
    line-height: 1.32;
    text-align: justify;
    color: #4a4a4a;
}

.h3{
    font-size: 26px;
    font-weight: 600;
    line-height: 1.32;
    text-align: center;
    color: #4a4a4a;
    margin: 0px 0px 50px 0px;
}

.button{
    padding: 15px 10px 15px 10px;
    margin: 0px 0px 0px 55px;
    text-align: center;
}


@media screen and (max-width: 767px) { /* Small Devices */
    .description{
        padding: 0px 10px 15px 10px;
        font-size: 15px;
    }
    
    .h3{
        font-size: 18px;
        margin: 0px 0px 60px 0px;
        position: relative;
    }
    
    .button{
        position: absolute;
        margin: 0px 0px 0px 0px;
        padding: 5px 5px 5px 5px;
        bottom: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px){ /* Medium to Large Devices */
    .description{
        padding: 0px 20px 20px 20px;
        font-size: 18px;
    }
    
    .h3{
        font-size: 24px;
        margin: 0px 0px 30px 0px;
    }
    
    .button{
        position: absolute;
        padding: 5px 5px 5px 5px;
        margin: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .description{
        padding: 0px 20px 20px 20px;
        font-size: 20px;
    }
    
    .h3{
        font-size: 26px;
    }
    
    .button{
        padding: 15px 5px 15px 5px;
        margin: 0px 0px 0px 20px;
    }  
}