.button{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color: rgba(255, 255, 255, 0.98);
    color: #6fdb70;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
}

.button:hover{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color:  #6fdb70;
    color:#ffffff;
    font-size: 18.5px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .button{
        border: solid 2px #6fdb70;
        font-size: 10px;
    }
    
    .button:hover{
        border: solid 2px #6fdb70;
        font-size: 11px;
    }
}

