.img {
    align-self: center;
    max-width: 100%;
    height: auto;
}

.values{
    margin: 60px 0px 60px 100px;
}

.name{
    text-shadow: 0 1px 0 #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.description{
    font-size: 20px;
    line-height: 1.32;
    text-align: justify;
    color: #4a4a4a;
    padding: 0px 10px 0px 0px;
}

.description li{
    padding: 5px 0px 0px 0px;
    list-style-type: none;
}

.description li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

@media screen and (max-width: 767px) { /* X-Small Devices */
    .img {
        max-width: 20%;
        height: auto;
    }
    
    .values{
        margin: 40px 0px 0px 0px;
    }
    
    .name{
        font-size: 14px;
        margin: 0px 60px 5px 0px;
    }
    
    .description{
        font-size: 14px;
        line-height: 1.32;
        text-align: justify;
        padding: 0px 0px 5px 8px;  
    }
    
    .description li{
        padding: 3px 0px 0px 0px;
    }

    .description li:before {
        padding-right: 0.3em;
    }
    
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .img {
        max-width: 20%;
    }
    
    .values{
        margin: 160px 0px 40px 0px;
    }
    
    .name{
        font-size: 20px;
        text-align: center;
        color: #000000;
    }
    
    .description{
        font-size: 18px;
        text-align: justify;
        padding: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .img {
        max-width: 20%;
    }
    
    .values{
        margin: 80px 0px 40px 10px;
    }
    
    .description{
        padding: 0px 0px 0px 10px;
    }      
}


