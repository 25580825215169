.description {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: normal;
    text-align: justify;
    color: #4a4a4a;
    padding:10px 60px 10px 45px;
}

.name{
    text-shadow: 0 1px 0 #ffffff;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding:0px 0px 0px 50px;
}

.img {
    align-self: center;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .description {
        font-size: 14px;  
        padding:10px 20px 10px 22px;
    }

    .name{
        font-size: 16px;  
        padding:0px 0px 0px 15px;   
    }
  }

  @media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .description {
        font-size: 16px;  
        padding:10px 1px 10px 1px;
    }

    .name{
        font-size: 19px;  
        padding:0px 0px 0px 0px;   
    }
  }



