.heroH1{
    margin-top: 240px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .heroH1{
        margin: 130px 10px 0px 10px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .heroH1{
        margin: 250px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .heroH1{
        margin: 230px 12px 0px 0px;
        padding: 0px 0px 0px 30px;
    }
}