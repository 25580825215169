.card{
    width: 490px;
    margin: 10px 0px 50px 40px;
}

.img{
    max-width: 100%;
    height: auto;
}

.title{
  text-shadow: 0 1px 0 #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.description{
    object-fit: contain;
    font-size: 14px;
    line-height: 1.54;
    text-align: justify;
    color: #4a4a4a;
}

.description li{
    padding: 0px 0px 20px 0px;
    list-style-type: none;
}

.description li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

.button{
    border-radius: 12px;
    border: solid 3px #6fdb70;
    background-color: rgba(255, 255, 255, 0.98);
    color: #6fdb70;
    font-size: 10px;
    font-weight: 900;
    margin: 0px 0px 0px 400px;

}

.button:hover{
    border-radius: 12px;
    border: solid 3px #6fdb70;
    background-color:  #6fdb70;
    color:#ffffff;
    font-size: 11px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .card{
        width: 310px;
        margin: 10px 0px 15px 0px;
    }
    
    .title{
      font-size: 12px;
    }
    
    .description{
        font-size: 10px;
    }
    
    .description li{
        padding: 5px 0px 5px 0px;
    }
    
    .button{
        font-size: 15px;
        margin: 0px 0px 0px 240px;
    }
    
    .button:hover{
        font-size: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .card{
        width: 320px;
        margin: 10px 0px 30px 0px;
    }
    
    .title{
      text-shadow: 0 1px 0 #ffffff;
      font-size: 14px;
    }
    
    .description{
        font-size: 12px;
    }
    
    .description li{
        padding: 10px 0px 5px 0px;
    }

    .button{
        margin: 0px 0px 0px 240px;
    
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .card{
        width: 370px;
        margin: 0px 0px 30px 40px;
    }
    
    .title{
      font-size: 17px;
      font-weight: 600;
    }
    
    .description{
        font-size: 12px;
    }
    
    .description li{
        padding: 10px 0px 5px 0px;
    }

    .button{
        margin: 0px 0px 0px 280px;
    }
}