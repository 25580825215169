.heroH1{
    margin-top: 190px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .heroH1{
        margin: 85px 10px 0px 10px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .heroH1{
        margin: 220px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .heroH1{
        margin: 260px 12px 0px 0px;
    }
}

@media screen and (min-width: 1501px) { /* XXLarge Devices */  
    .heroH1{
        margin: 240px 12px 0px 0px;
    }
}
