body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.HomeServices_description__1lsTz {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: normal;
    text-align: justify;
    color: #4a4a4a;
    padding:10px 60px 10px 45px;
}

.HomeServices_name__s-me6{
    text-shadow: 0 1px 0 #ffffff;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding:0px 0px 0px 50px;
}

.HomeServices_img__1kxY9 {
    align-self: center;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .HomeServices_description__1lsTz {
        font-size: 14px;  
        padding:10px 20px 10px 22px;
    }

    .HomeServices_name__s-me6{
        font-size: 16px;  
        padding:0px 0px 0px 15px;   
    }
  }

  @media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .HomeServices_description__1lsTz {
        font-size: 16px;  
        padding:10px 1px 10px 1px;
    }

    .HomeServices_name__s-me6{
        font-size: 19px;  
        padding:0px 0px 0px 0px;   
    }
  }




.BodyHeader_bodyHeader__fqh8n{
    font-size: 35px;
    padding: 45px 0px 10px 45px;
    color: #000000;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.BodyHeader_line__3HUpK {
    width: 250px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #fde582, #ccdba8 31%, #99d1c8 69%, #0ec0fd);
    border-image-slice: 1;
    margin-left: 45px;
    margin-bottom: 60px;  
}

@media screen and (max-width: 767px) { /* Small Devices */
    .BodyHeader_bodyHeader__fqh8n{
        font-size: 25px;
        padding: 0px 0px 7px 0px;
    }
    
    .BodyHeader_line__3HUpK {
        width: 200px;
        margin-left: 0px;
        margin-bottom: 30px;  
    } 
}

@media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .BodyHeader_bodyHeader__fqh8n{
        font-size: 32px;
        padding: 50px 0px 10px 0px;
    }
    
    .BodyHeader_line__3HUpK {
        width: 250px;
        margin-left: 0px;
        margin-bottom: 50px;  
    } 
}

.Header_navbarToggler__1gG0N{
  color: #6fdb70;
}

.Header_logo__4a_wV{
  width: 300px;
  max-width: 100%;
  height: auto;
}

.Header_navItem__2NjJj{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin: 15px 10px 0px 10px;
}

.Header_navItem__2NjJj:hover{
  color: #6fdb70;
}

.Header_navSubItem__19ten{
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #000000;  
}

.Header_navSubItem__19ten:hover{
  color: #6fdb70;
}

.Header_navBar__2p1qL{ 
  background-color: white !important;
  opacity: 0.80; 
  width: 100%;
  padding-top: 20px;
}

.Header_container__kLPH_{
  margin-left: 22%;
}

  @media screen and (max-width: 767px) { /* Small Devices */
    .Header_logo__4a_wV{
      max-width: 50%;
      height: auto;
      margin: 0px 0px 0px 90px;
  }
    .Header_navItem__2NjJj{
      font-size: 9px;
      margin: 0px 0px 0px 0px;
    }
  
    .Header_navSubItem__19ten{
      font-size: 8px;
    }

    .Header_navBar__2p1qL{
      padding-top: 0px;
    }

    .Header_container__kLPH_{
      margin-left: 0%;
    }
  }

  @media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .Header_navItem__2NjJj{
      font-size: 11px;
      margin: 10px 10px 0px 10px;
    }
  
    .Header_navSubItem__19ten{
      font-size: 10px;
    }

    .Header_navBar__2p1qL{
      padding-top: 12px;
    }

    .Header_container__kLPH_{
      margin-left: 2%;
    }
  }

  @media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
  .Header_navItem__2NjJj{
      font-size: 13px;
      margin: 15px 10px 0px 10px;
    }
  
    .Header_navSubItem__19ten{
      font-size: 11px;
    }

    .Header_navBar__2p1qL{
      padding-top: 15px;
    }

    .Header_container__kLPH_{
      margin-left: 12%;
    }
  }

  @media screen and (min-width: 1201px) and (max-width:1450px) { /* XLarge Devices */  
    .Header_navItem__2NjJj{
        font-size: 14px;
        margin: 15px 10px 0px 10px;
      }
    
      .Header_navSubItem__19ten{
        font-size: 12px;
      }
  
      .Header_navBar__2p1qL{
        padding-top: 15px;
      }
    }

    @media screen and (min-width: 1451px) and (max-width:1900px) { /* XXLarge Devices */  
        .Header_navBar__2p1qL{
          padding-top: 15px;
        }

        .Header_container__kLPH_{
          margin-left: 25%;
        }
      }

      @media screen and (min-width: 1901px) { /* XXXLarge Devices */  
        .Header_navBar__2p1qL{
          padding-top: 15px;
        }

        .Header_container__kLPH_{
          margin-left: 35%;
        }
      }
.Footer_primaryFooter__1Cf8L{
    background-color: rgba(121, 219, 111, 0.64);
    margin:0px auto;
    padding: 10px 0px 0px 0px;
}

.Footer_footerMenu__CK96a{
    list-style: none;
    font-size: 16px;  
    padding: 0px; 
}

.Footer_footerMenu__CK96a li a{
    color: black;
}

.Footer_h5Title__1szrc {
    color: white;  
    font-size: 16px;
}

.Footer_sortUp__Okb-N{
    color: white;
    margin: 0px 0px 0px 100px;
}

.Footer_logoImg__1Bmkb{
    width: 239px;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 0px 0px 10px 0px;
}

.Footer_address__7lBZz{
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: #000000;
    margin: 0px 0px 30px 0px;
}

.Footer_cta__2fKcT {   
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  color: #000000;
  margin: 0px 130px 25px 0px; 
}

.Footer_linkedinIcon__3jCCH{
    color: #ffffff;
}

.Footer_emailIcon__1tbr6{
    color: #ffffff;
}

.Footer_connectSection__3I22O{
    margin: 0px 0px 0px 0px;
}

.Footer_secondaryFooter__1OUN3{
    color: #000000;
    text-align: center;
    background-color: #ffffff;
    margin: 20px 0px 0px 0px;
    font-size: 13px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .Footer_primaryFooter__1Cf8L{
        padding: 0px 0px 0px 0px;
    }
    .Footer_footerMenu__CK96a{
        font-size: 11px;  
    }

    .Footer_h5Title__1szrc { 
        font-size: 11px;
    }

    .Footer_sortUp__Okb-N{
        margin: 0px 0px 0px 70px;
    }

    .Footer_logoImg__1Bmkb{
        margin: 0px 0px 0px 0px;
        padding: 0px 20px 0px 0px;
    }
    
    .Footer_address__7lBZz{
        font-size: 11px;
        line-height: 1;
    }
    
    .Footer_cta__2fKcT {   
      font-size: 11px;
      margin: 0px 50px 5px 0px; 
    }

    .Footer_connectSection__3I22O{
        margin: 0px 0px 15px 30px;
    }
    
    .Footer_secondaryFooter__1OUN3{
        font-size: 8px;
        margin: 15px 0px 0px 0px;
        line-height: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .Footer_footerMenu__CK96a{
        font-size: 15px;  
    }

    .Footer_h5Title__1szrc { 
        font-size: 15px;
    }

    .Footer_sortUp__Okb-N{
        margin: 0px 0px 0px 100px;
    }
    
    .Footer_address__7lBZz{
        font-size: 13px;
    }
    
    .Footer_cta__2fKcT {   
      font-size: 15px;
      margin: 20px 20px 25px 0px; 
      line-height: 1.2;
    }
    
    .Footer_secondaryFooter__1OUN3{
        font-size: 10px;
    }
  }




.HeroUnit_heroImage__1LNo0{
    width: 100%;
    max-height: 700px;
    max-width: 100%; 
    height: auto; 
    margin-top: 100px;
    position: relative;
    object-fit: cover; 
}

.HeroUnit_h1__1JAuX { 
    position: absolute; 
    padding: 0px 200px 0px 200px;
    margin-top: 260px;
    text-align: center;
    font-size: 80px;
    line-height: 1.5em;
    font-weight: bold;
    color: #ffffff;
 }
 
 @media screen and (max-width: 568px) { /* X-Small Devices */
    .HeroUnit_heroImage__1LNo0{
        margin-top: 0px;
    }
    
    .HeroUnit_h1__1JAuX { 
        padding: 0px 0px 0px 15px;
        margin-top: 70px;
        text-align: center;
        font-size: 28px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     }
  }

 @media screen and (min-width: 569px) and (max-width: 767px) { /* Small Devices */
    .HeroUnit_heroImage__1LNo0{
        margin-top: 0px;
    }
    
    .HeroUnit_h1__1JAuX { 
        padding: 0px 0px 0px 15px;
        margin-top: 110px;
        text-align: center;
        font-size: 28px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     }
  }

  @media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .HeroUnit_heroImage__1LNo0{
        margin-top: 80px;
    }
    
    .HeroUnit_h1__1JAuX { 
        padding: 0px 0px 0px 10px;
        margin-top: 250px;
        text-align: center;
        font-size: 58px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     } 
  }

  @media screen and (min-width: 993px) and (max-width:1400px) { /* Large to X-Large Devices */
    .HeroUnit_heroImage__1LNo0{
        margin-top: 80px;
    }
    
    .HeroUnit_h1__1JAuX { 
        padding: 0px 0px 0px 40px;
        margin-top: 260px;
        text-align: center;
        font-size: 80px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     } 
  }

.ClickableButton_button__2lyEI{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color: rgba(255, 255, 255, 0.98);
    color: #6fdb70;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
}

.ClickableButton_button__2lyEI:hover{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color:  #6fdb70;
    color:#ffffff;
    font-size: 18.5px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .ClickableButton_button__2lyEI{
        border: solid 2px #6fdb70;
        font-size: 10px;
    }
    
    .ClickableButton_button__2lyEI:hover{
        border: solid 2px #6fdb70;
        font-size: 11px;
    }
}


.HomeFeatures_description__1ch0a{
    font-size: 20px;
    line-height: 1.4;
    text-align: justify;
    color: #4a4a4a;
    padding: 0px 10px 0px 60px;
    position: relative;
    top:90px;
}

.HomeFeatures_homeFeaturesImg__29lbd{
    max-width: 100%; 
    height: auto; 
}

.HomeFeatures_buttonServices__1QxPZ{
    text-align: center;
    position: relative;
    bottom: 400px;
    left: 15px;
}

.HomeFeatures_buttonBlog__1J65Z{
    text-align: center;
    position: relative;
    bottom: 400px;
    left: 50px;
}

.HomeFeatures_buttonContact__3dfYV{
    text-align: center;
    position: absolute;
    bottom: 170px;
    left: 430px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .HomeFeatures_description__1ch0a{
        font-size: 14px;
        line-height: 1.4;
        position: relative;
        top:0px;
        padding: 0px 10px 0px 10px;
    }

    .HomeFeatures_buttonServices__1QxPZ{
        font-size: 8.5px;
        bottom: 170px;
        left: 2px;
        padding: 3px 3px 3px 3px;
    }
    .HomeFeatures_buttonServices__1QxPZ:hover{
        font-size: 9px;
    }
    
    .HomeFeatures_buttonBlog__1J65Z{
        font-size: 8.5px;
        position: absolute;
        bottom: 150px;
        left: 200px;
        padding: 3px 3px 3px 3px;
        margin: 0px 20px 0px 35px;
    }
    .HomeFeatures_buttonBlog__1J65Z:hover{
        font-size: 9px;
    }
    
    .HomeFeatures_buttonContact__3dfYV{
        font-size: 8.5px;
        bottom: 40px;
        left: 210px;
        padding: 3px 3px 3px 3px;
        margin: 0px 25px 0px 35px;
    }
    .HomeFeatures_buttonContact__3dfYV:hover{
        font-size: 9px;
    }
}
    

  @media screen and (min-width: 768px) and (max-width:991px) { /* Medium Devices */
    .HomeFeatures_description__1ch0a{
        font-size: 16px;
        line-height: 1.4;
        position: relative;
        top: 20px;
        padding: 0px 5px 0px 10px;
    }

    .HomeFeatures_homeFeaturesImg__29lbd{
        max-width: 100%; 
    }
    
    .HomeFeatures_buttonServices__1QxPZ{
        font-size: 11.5px;
        bottom: 250px;
        left: 1px;
        padding: 10px 5px 10px 5px;
    }
    .HomeFeatures_buttonServices__1QxPZ:hover{
        font-size: 12px;
    }
    
    .HomeFeatures_buttonBlog__1J65Z{
        font-size: 11.5px;
        position: absolute;
        bottom: 230px;
        left: 220px;
        padding: 5px 5px 5px 5px;
        margin: 0px 50px 0px 50px;
    }
    .HomeFeatures_buttonBlog__1J65Z:hover{
        font-size: 12px;
    }
    
    .HomeFeatures_buttonContact__3dfYV{
        font-size: 11.5px;
        bottom: 100px;
        left: 265px;
        padding: 10px 5px 10px 5px;
    }
    .HomeFeatures_buttonContact__3dfYV:hover{
        font-size: 12px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /*Large Devices */
    .HomeFeatures_description__1ch0a{
        font-size: 19px;
        line-height: 1.4;
        position: relative;
        top: 50px;
        padding: 0px 10px 0px 56px;
    }

    .HomeFeatures_homeFeaturesImg__29lbd{
        max-width: 100%; 
    }
    
    .HomeFeatures_buttonServices__1QxPZ{
        font-size: 16px;
        bottom: 330px;
        left: 5px;
        padding: 10px 5px 10px 5px;
    }
    .HomeFeatures_buttonServices__1QxPZ:hover{
        font-size: 16.5px;
    }
    
    .HomeFeatures_buttonBlog__1J65Z{
        font-size: 16px;
        position: absolute;
        bottom: 320px;
        left: 307px;
        padding: 5px 5px 5px 5px;
    }
    .HomeFeatures_buttonBlog__1J65Z:hover{
        font-size: 16.5px;
    }
    
    .HomeFeatures_buttonContact__3dfYV{
        font-size: 16px;
        bottom: 130px;
        left: 355px;
        padding: 10px 5px 10px 5px;
    }
    .HomeFeatures_buttonContact__3dfYV:hover{
        font-size: 16.5px;
    } 
}


.HomeClients_container__T0N5_{
    margin-bottom: 100px;  
}

.HomeClients_row1__5RWAQ{
    margin: 0px 0px 0px 100px;
}

.HomeClients_row2__J4-Q8{
    margin: 0px 0px 0px 100px;
}

.HomeClients_curaLogo__1GdC9{
    max-width: 40%; 
    height: auto; 
}

.HomeClients_ingredionLogo__1y-GM{
    max-width: 50%; 
    height: auto; 
    margin: 0px 0px 0px 0px;
}

.HomeClients_igyLogo__20taG{
    max-width: 35%; 
    height: auto; 
}

.HomeClients_purFoodsLogo__WdFBd{
    max-width: 50%; 
    height: auto; 
    margin: 50px 0px 0px 0px;
}

.HomeClients_dianaLogo__1WO1T{
    max-width: 70%; 
    height: auto; 
    margin: 50px 0px 0px 0px;
}

.HomeClients_gripLogo__1YN5c{
    max-width: 100%; 
    height: auto; 
    margin: 40px 0px 0px 0px;
}

.HomeClients_dianaText__181lY{
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #4a4a4a;
    position: absolute;
    left: 70px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .HomeClients_container__T0N5_{
        margin-bottom: 50px;  
    }
    
    .HomeClients_row1__5RWAQ{
        margin: 0px 0px 0px 0px;
    }
    
    .HomeClients_row2__J4-Q8{
        margin: 0px 0px 0px 0px;
    }
    
    .HomeClients_curaLogo__1GdC9{
        margin: 0px 0px 0px 65px;
    }
    
    .HomeClients_ingredionLogo__1y-GM{
        margin: 20px 0px 0px 57px;
    }
    
    .HomeClients_igyLogo__20taG{
        margin: 20px 0px 0px 75px;
    }
    
    .HomeClients_purFoodsLogo__WdFBd{
        margin: 20px 0px 0px 72px;
    }
    
    .HomeClients_dianaLogo__1WO1T{
        margin: 20px 0px 0px 60px;
    }
    
    .HomeClients_gripLogo__1YN5c{
        margin: 30px 0px 0px 70px;
    }
    
    .HomeClients_dianaText__181lY{
        font-size: 12px;
        text-align: left;
        left: 120px;
    }    
}  

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium Devices */
    .HomeClients_container__T0N5_{
        margin-bottom: 60px;  
    }
    
    .HomeClients_row1__5RWAQ{
        margin: 0px 0px 0px 70px;
    }
    
    .HomeClients_row2__J4-Q8{
        margin: 0px 0px 0px 70px;
    }
    
    .HomeClients_curaLogo__1GdC9{
        max-width: 60%; 
    }
    
    .HomeClients_ingredionLogo__1y-GM{
        max-width: 70%; 
    }
    
    .HomeClients_igyLogo__20taG{
        max-width: 55%; 
    }
    
    .HomeClients_purFoodsLogo__WdFBd{
        max-width: 70%; 
    }
    
    .HomeClients_dianaLogo__1WO1T{
        max-width: 90%; 
    }
    
    .HomeClients_gripLogo__1YN5c{
        max-width: 100%; 
    }
    
    .HomeClients_dianaText__181lY{
        font-size: 13px;
        padding: 0px 10px 0px 10px;
        left: 50px;
    } 
}

@media screen and (min-width: 992px) and (max-width:1200px) { /*Large Devices */
    .HomeClients_container__T0N5_{
        margin-bottom: 80px;  
    }
    
    .HomeClients_row1__5RWAQ{
        margin: 0px 0px 0px 70px;
    }
    
    .HomeClients_row2__J4-Q8{
        margin: 0px 0px 0px 70px;
    }
    
    .HomeClients_curaLogo__1GdC9{
        max-width: 60%; 
    }
    
    .HomeClients_ingredionLogo__1y-GM{
        max-width: 70%; 
    }
    
    .HomeClients_igyLogo__20taG{
        max-width: 55%; 
    }
    
    .HomeClients_purFoodsLogo__WdFBd{
        max-width: 70%; 
    }
    
    .HomeClients_dianaLogo__1WO1T{
        max-width: 90%; 
    }
    
    .HomeClients_gripLogo__1YN5c{
        max-width: 100%; 
    }
    
    .HomeClients_dianaText__181lY{
        font-size: 13px;
        padding: 0px 10px 0px 10px;
        left: 90px;
    }     
}


.Home_heroH1__1Fr8Z{
    margin-top: 190px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .Home_heroH1__1Fr8Z{
        margin: 85px 10px 0px 10px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .Home_heroH1__1Fr8Z{
        margin: 220px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .Home_heroH1__1Fr8Z{
        margin: 260px 12px 0px 0px;
    }
}

@media screen and (min-width: 1501px) { /* XXLarge Devices */  
    .Home_heroH1__1Fr8Z{
        margin: 240px 12px 0px 0px;
    }
}

.AboutDFNSummary_description__3i_Z8{
    padding: 0px 100px 20px 100px;
    font-size: 20px;
    line-height: 1.32;
    text-align: justify;
    color: #4a4a4a;
}

.AboutDFNSummary_h3__1HAh9{
    font-size: 26px;
    font-weight: 600;
    line-height: 1.32;
    text-align: center;
    color: #4a4a4a;
    margin: 0px 0px 50px 0px;
}

.AboutDFNSummary_button__2FQgm{
    padding: 15px 10px 15px 10px;
    margin: 0px 0px 0px 55px;
    text-align: center;
}


@media screen and (max-width: 767px) { /* Small Devices */
    .AboutDFNSummary_description__3i_Z8{
        padding: 0px 10px 15px 10px;
        font-size: 15px;
    }
    
    .AboutDFNSummary_h3__1HAh9{
        font-size: 18px;
        margin: 0px 0px 60px 0px;
        position: relative;
    }
    
    .AboutDFNSummary_button__2FQgm{
        position: absolute;
        margin: 0px 0px 0px 0px;
        padding: 5px 5px 5px 5px;
        bottom: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px){ /* Medium to Large Devices */
    .AboutDFNSummary_description__3i_Z8{
        padding: 0px 20px 20px 20px;
        font-size: 18px;
    }
    
    .AboutDFNSummary_h3__1HAh9{
        font-size: 24px;
        margin: 0px 0px 30px 0px;
    }
    
    .AboutDFNSummary_button__2FQgm{
        position: absolute;
        padding: 5px 5px 5px 5px;
        margin: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .AboutDFNSummary_description__3i_Z8{
        padding: 0px 20px 20px 20px;
        font-size: 20px;
    }
    
    .AboutDFNSummary_h3__1HAh9{
        font-size: 26px;
    }
    
    .AboutDFNSummary_button__2FQgm{
        padding: 15px 5px 15px 5px;
        margin: 0px 0px 0px 20px;
    }  
}
.AboutDFNValues_img__37B59 {
    align-self: center;
    max-width: 100%;
    height: auto;
}

.AboutDFNValues_values__3ysnQ{
    margin: 60px 0px 60px 100px;
}

.AboutDFNValues_name__1qqbv{
    text-shadow: 0 1px 0 #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.AboutDFNValues_description__1yNUl{
    font-size: 20px;
    line-height: 1.32;
    text-align: justify;
    color: #4a4a4a;
    padding: 0px 10px 0px 0px;
}

.AboutDFNValues_description__1yNUl li{
    padding: 5px 0px 0px 0px;
    list-style-type: none;
}

.AboutDFNValues_description__1yNUl li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

@media screen and (max-width: 767px) { /* X-Small Devices */
    .AboutDFNValues_img__37B59 {
        max-width: 20%;
        height: auto;
    }
    
    .AboutDFNValues_values__3ysnQ{
        margin: 40px 0px 0px 0px;
    }
    
    .AboutDFNValues_name__1qqbv{
        font-size: 14px;
        margin: 0px 60px 5px 0px;
    }
    
    .AboutDFNValues_description__1yNUl{
        font-size: 14px;
        line-height: 1.32;
        text-align: justify;
        padding: 0px 0px 5px 8px;  
    }
    
    .AboutDFNValues_description__1yNUl li{
        padding: 3px 0px 0px 0px;
    }

    .AboutDFNValues_description__1yNUl li:before {
        padding-right: 0.3em;
    }
    
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .AboutDFNValues_img__37B59 {
        max-width: 20%;
    }
    
    .AboutDFNValues_values__3ysnQ{
        margin: 160px 0px 40px 0px;
    }
    
    .AboutDFNValues_name__1qqbv{
        font-size: 20px;
        text-align: center;
        color: #000000;
    }
    
    .AboutDFNValues_description__1yNUl{
        font-size: 18px;
        text-align: justify;
        padding: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .AboutDFNValues_img__37B59 {
        max-width: 20%;
    }
    
    .AboutDFNValues_values__3ysnQ{
        margin: 80px 0px 40px 10px;
    }
    
    .AboutDFNValues_description__1yNUl{
        padding: 0px 0px 0px 10px;
    }      
}



.AboutDFN_heroH1__2QSwj{
    margin-top: 240px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .AboutDFN_heroH1__2QSwj{
        margin: 130px 10px 0px 10px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .AboutDFN_heroH1__2QSwj{
        margin: 250px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .AboutDFN_heroH1__2QSwj{
        margin: 230px 12px 0px 0px;
        padding: 0px 0px 0px 30px;
    }
}
.AboutZDFStory_ZDFImg__2CAmO{
    max-width: 100%;
    height: auto;
    padding: 0px 20px 0px 0px;
    margin: 0px 0px 0px 50px;
    float: left;
}

.AboutZDFStory_description__3Cw0A{
    object-fit: contain;
    font-size: 15px;
    line-height: 1.54;
    text-align: justify;
    color: #4a4a4a;
}

.AboutZDFStory_colRightStyle__1FY9T{
    padding: 0px 70px 0px 70px;
}

.AboutZDFStory_colLeftStyle__AJ0cn{
    margin: 0px 0px 0px 50px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .AboutZDFStory_ZDFImg__2CAmO{
        max-width: 50%;
        padding: 0px 10px 0px 0px;
        margin: 0px 0px 0px 0px;
    }
    
    .AboutZDFStory_description__3Cw0A{
        font-size: 12px;
    }
    
    .AboutZDFStory_colRightStyle__1FY9T{
        padding: 10px 0px 0px 0px;
    }
    
    .AboutZDFStory_colLeftStyle__AJ0cn{
        margin: 0px 0px 0px 0px;
    }
}


@media screen and (min-width: 576px) and (max-width:767px) { /* Small Devices */
    .AboutZDFStory_ZDFImg__2CAmO{
        max-width: 50%;
        padding: 0px 10px 0px 0px;
        margin: 0px 0px 0px 0px;
    }
    
    .AboutZDFStory_description__3Cw0A{
        font-size: 13px;
    }
    
    .AboutZDFStory_colRightStyle__1FY9T{
        padding: 10px 0px 0px 0px;
    }
    
    .AboutZDFStory_colLeftStyle__AJ0cn{
        margin: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .AboutZDFStory_ZDFImg__2CAmO{
        max-width: 50%;
        padding: 0px 20px 0px 0px;
        margin: 0px 0px 20px 0px;
    }

    .AboutZDFStory_description__3Cw0A{
        font-size: 14px;
    }
    
    .AboutZDFStory_colRightStyle__1FY9T{
        padding: 0px 20px 0px 20px;
    }
    
    .AboutZDFStory_colLeftStyle__AJ0cn{
        margin: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  

}
.AboutZDFEducAffil_educAffImg__36BMp{
    margin: 0px 0px 0px 50px;
    max-width: 90%;
    height: auto;
}

.AboutZDFEducAffil_title__2GBhG{
    text-shadow: 0 1px 0 #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #000000;   
}

.AboutZDFEducAffil_description__1DnKe{
    object-fit: contain;
    font-size: 15px;
    line-height: 1.54;
    text-align: justify;
    color: #4a4a4a;
    padding: 0px 60px 0px 40px;
}

.AboutZDFEducAffil_description__1DnKe li{
    padding: 5px 0px 0px 0px;
    list-style-type: none;
}

.AboutZDFEducAffil_description__1DnKe li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

.AboutZDFEducAffil_affilRow__B_o6l{
    margin-bottom: 30px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .AboutZDFEducAffil_educAffImg__36BMp{
        margin: 80px 0px 0px 20px;
        max-width: 100%;
    }
    
    .AboutZDFEducAffil_title__2GBhG{
        font-size: 12px;
        font-weight: 600;
        margin: 0px 0px 0px 45px;
    }
    
    .AboutZDFEducAffil_description__1DnKe{
        object-fit: contain;
        font-size: 10px;
        line-height: 1.54;
        text-align: justify;
        color: #4a4a4a;
        padding: 0px 10px 0px 0px;
        width: 240px;
    }
    
    .AboutZDFEducAffil_description__1DnKe li{
        padding: 5px 0px 0px 0px;
        list-style-type: none;
    }
    
    .AboutZDFEducAffil_description__1DnKe li:before {
        content: '\2022';
        color:rgba(121, 219, 111, 0.64);
        padding-right: 0.5em;
    }
    
    .AboutZDFEducAffil_affilRow__B_o6l{
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .AboutZDFEducAffil_educAffImg__36BMp{
        margin: 40px 0px 0px 20px;
        max-width: 100%;
    }
    
    .AboutZDFEducAffil_title__2GBhG{
        font-size: 18px;  
    }
    
    .AboutZDFEducAffil_description__1DnKe{
        width: 500px;
        font-size: 13px;
        padding: 0px 30px 0px 10px;
    }

    .AboutZDFEducAffil_affilRow__B_o6l{
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .AboutZDFEducAffil_educAffImg__36BMp{
        margin: 20px 0px 0px 60px;
        max-width: 90%;
    }
    
    .AboutZDFEducAffil_title__2GBhG{
        font-size: 20px;  
    }
    
    .AboutZDFEducAffil_description__1DnKe{
        width: 600px;
        font-size: 15px;
        padding: 0px 80px 0px 30px;
    }

    .AboutZDFEducAffil_affilRow__B_o6l{
        margin-bottom: 20px;
    }
}

.Publication_card__3djrZ{
    width: 490px;
    margin: 10px 0px 50px 40px;
}

.Publication_img__2BTmq{
    max-width: 100%;
    height: auto;
}

.Publication_title__1w-R4{
  text-shadow: 0 1px 0 #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.Publication_description__h5N6L{
    object-fit: contain;
    font-size: 14px;
    line-height: 1.54;
    text-align: justify;
    color: #4a4a4a;
}

.Publication_description__h5N6L li{
    padding: 0px 0px 20px 0px;
    list-style-type: none;
}

.Publication_description__h5N6L li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

.Publication_button__8FYbQ{
    border-radius: 12px;
    border: solid 3px #6fdb70;
    background-color: rgba(255, 255, 255, 0.98);
    color: #6fdb70;
    font-size: 10px;
    font-weight: 900;
    margin: 0px 0px 0px 400px;

}

.Publication_button__8FYbQ:hover{
    border-radius: 12px;
    border: solid 3px #6fdb70;
    background-color:  #6fdb70;
    color:#ffffff;
    font-size: 11px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .Publication_card__3djrZ{
        width: 310px;
        margin: 10px 0px 15px 0px;
    }
    
    .Publication_title__1w-R4{
      font-size: 12px;
    }
    
    .Publication_description__h5N6L{
        font-size: 10px;
    }
    
    .Publication_description__h5N6L li{
        padding: 5px 0px 5px 0px;
    }
    
    .Publication_button__8FYbQ{
        font-size: 15px;
        margin: 0px 0px 0px 240px;
    }
    
    .Publication_button__8FYbQ:hover{
        font-size: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .Publication_card__3djrZ{
        width: 320px;
        margin: 10px 0px 30px 0px;
    }
    
    .Publication_title__1w-R4{
      text-shadow: 0 1px 0 #ffffff;
      font-size: 14px;
    }
    
    .Publication_description__h5N6L{
        font-size: 12px;
    }
    
    .Publication_description__h5N6L li{
        padding: 10px 0px 5px 0px;
    }

    .Publication_button__8FYbQ{
        margin: 0px 0px 0px 240px;
    
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .Publication_card__3djrZ{
        width: 370px;
        margin: 0px 0px 30px 40px;
    }
    
    .Publication_title__1w-R4{
      font-size: 17px;
      font-weight: 600;
    }
    
    .Publication_description__h5N6L{
        font-size: 12px;
    }
    
    .Publication_description__h5N6L li{
        padding: 10px 0px 5px 0px;
    }

    .Publication_button__8FYbQ{
        margin: 0px 0px 0px 280px;
    }
}
.AboutZDF_heroH1__tIGzH{
    margin-top: 230px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .AboutZDF_heroH1__tIGzH{
        margin: 70px 5px 0px 5px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .AboutZDF_heroH1__tIGzH{
        margin: 210px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .AboutZDF_heroH1__tIGzH{
        margin: 250px 0px 0px 0px;
    }
}

.ServicesDFN_img__2ZP7e{
    margin: 120px 0px 0px 0px;
    max-width: 100%;
    height: auto;
    width: 550px;
}

.ServicesDFN_descripTitle__mgfqb{
    object-fit: contain;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.32;
    text-align: center;
    color: #4a4a4a;
}

.ServicesDFN_description__25IVD{
    padding: 0px 0px 0px 30px;
    object-fit: contain;
    font-size: 20px;
    line-height: 1.25;
    text-align: left;
    color: #4a4a4a;
    margin: 0px 0px 60px 0px;
}

.ServicesDFN_description__25IVD li{
    padding: 20px 0px 0px 0px;
    list-style-type: none;
}

.ServicesDFN_description__25IVD li:before {
    content: '\2022';
    color:rgba(121, 219, 111, 0.64);
    padding-right: 0.5em;
}

.ServicesDFN_capTitle__1NC4A{
    object-fit: contain;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.32;
    text-align: center;
    color: #4a4a4a;
    margin: 50px 0px 20px 0px;
}

@media screen and (max-width: 767px) { /* Small Devices */
    .ServicesDFN_img__2ZP7e{
        margin: 0px 0px 30px 35px;
        max-width: 80%;
    }
    
    .ServicesDFN_descripTitle__mgfqb{
        font-size: 14px;
        line-height: 0;
    }
    
    .ServicesDFN_description__25IVD{
        padding: 0px 10px 0px 10px;
        font-size: 12px;
        margin: 0px 0px 40px 0px;
    }
    
    .ServicesDFN_description__25IVD li{
    padding: 15px 0px 0px 0px;
    }
    
    .ServicesDFN_capTitle__1NC4A{
        object-fit: contain;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.32;
        margin: 30px 0px 15px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .ServicesDFN_img__2ZP7e{
        margin: 60px 0px 0px 0px;
        max-width: 100%;
    }
    
    .ServicesDFN_descripTitle__mgfqb{
        font-size: 18px;
        line-height: 0;
    }
    
    .ServicesDFN_description__25IVD{
        padding: 0px 0px 0px 10px;
        font-size: 14px;
    }
    
    .ServicesDFN_description__25IVD li{
    padding: 15px 0px 0px 0px;
    }
    
    .ServicesDFN_capTitle__1NC4A{
        object-fit: contain;
        font-size: 18px;
        margin: 30px 0px 15px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .ServicesDFN_img__2ZP7e{
        margin: 40px 0px 0px 0px;
        max-width: 100%;
    }
    
    .ServicesDFN_descripTitle__mgfqb{
        font-size: 20px;
        line-height:0;
    }
    
    .ServicesDFN_description__25IVD{
        padding: 0px 0px 10px 10px;
        font-size: 17px;
    }
    
    .ServicesDFN_description__25IVD li{
    padding: 15px 0px 0px 0px;
    }
    
    .ServicesDFN_capTitle__1NC4A{
        object-fit: contain;
        font-size: 20px;
        margin: 30px 0px 15px 0px;
    }
}


.ProductInnovation_heroH1__2NeQ9{
    color: black;
    margin-top: 280px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .ProductInnovation_heroH1__2NeQ9{
        margin: 75px 1px 0px 1px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .ProductInnovation_heroH1__2NeQ9{
        margin: 220px 10px 0px 13px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .ProductInnovation_heroH1__2NeQ9{
        margin: 230px 0px 0px 0px;
    }
}
.BrandStory_heroH1__3tpbq{
    color: white;
    margin-top: 230px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .BrandStory_heroH1__3tpbq{
        margin: 75px 0px 0px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .BrandStory_heroH1__3tpbq{
        margin: 200px 5px 0px 5px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .BrandStory_heroH1__3tpbq{
        margin: 225px 0px 0px 0px;
    }
}
.NewsCarousel_carousel__3p-H3{
    margin: 0px 0px 80px 0px; 
    max-width: 100%;
    height: auto;
}

.NewsCarousel_backgrImg__1uYBE{
    max-width: 100%;
    height: auto;
}

.NewsCarousel_caption__20ius{
    width: 800px;
    height: 160px;
    border-radius: 13px;
    background-color: rgba(229, 237, 228, 0.8);
    color: black;
    font-size: 18px;
    margin: 0px 0px 40px 0px; 
}

.NewsCarousel_controls__mrf3y {
    color:  rgba(121, 219, 111, 0.8);
}

@media screen and (max-width: 767px) { /* Small Devices */
    .NewsCarousel_carousel__3p-H3{
        margin: 0px 0px 30px 0px; 
    }
    
    .NewsCarousel_caption__20ius{
        width: 150px;
        height: 100px;
        font-size: 10px;
        margin: 0px 0px 0px 0px; 
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .NewsCarousel_carousel__3p-H3{
        margin: 0px 10px 60px 0px; 
    }
    
    .NewsCarousel_caption__20ius{
        width: 500px;
        height: 170px;
        font-size: 12px;
        margin: 0px 0px 20px 0px; 
    }
}

@media screen and (min-width: 992px) and (max-width:1400px) { /* Large to XLarge Devices */  
    .NewsCarousel_carousel__3p-H3{
        margin: 0px 0px 60px 0px; 
    }
    
    .NewsCarousel_caption__20ius{
        width: 700px;
        height: 160px;
        font-size: 18px;
        margin: 0px 0px 40px 0px; 
    }
}

.News_heroH1__2_6W6{
    color: white;
    margin-top: 250px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .News_heroH1__2_6W6{
        margin: 130px 10px 0px 10px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .News_heroH1__2_6W6{
        margin: 260px 5px 0px 5px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .News_heroH1__2_6W6{
        margin: 280px 0px 0px 0px;
    }
}


@media screen and (min-width: 1201px) and (max-width:1450px) { /* XLarge Devices */  
    .News_heroH1__2_6W6{
        margin: 270px 10px 0px 10px;
    }
}

@media screen and (min-width: 1451px) and (max-width:1900px) { /* XXLarge Devices */  
    .News_heroH1__2_6W6{
        margin: 290px 10px 0px 10px;
    }
}

@media screen and (min-width: 1901px) { /* XXXLarge Devices */  
    .News_heroH1__2_6W6{
        margin: 350px 0px 0px 380px;
    }
}
.ContactForm_container__1b4p4{
    border: solid 2px #0fc0fd;
    margin-bottom: 80px;
}

.ContactForm_colLeft__3psps{
    background-color: rgba(48, 176, 219, 0.4);
}

.ContactForm_iconLogo__1m_ze{
    width: 130px;
    max-width: 100%;
    height: auto;
    margin: 80px 0px 50px 220px;
}

.ContactForm_contactInfo__1ZQql{
    object-fit: contain;
    font-size: 17px;
    line-height: 1.4;
    text-align: center;
    color: #000000;
}

.ContactForm_error__2Tv-z{
    color: red;
}

.ContactForm_success__372Em{
    color: green;
}

.ContactForm_line__2KBC3{
    width: 250px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #fde582, #ccdba8 31%, #99d1c8 69%, #0ec0fd);
    border-image-slice: 1;
    margin: 25px 0px 25px 140px;  
}

.ContactForm_form__3C9Dp{
    margin: 30px 60px 30px 60px;
}

.ContactForm_inputText__3w5es{
    font-size: 17px;
    color: #4a4a4a;
}

.ContactForm_label__4XDTO{
    font-size: 17px;
}

.ContactForm_button__dDyVN{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color: rgba(255, 255, 255, 0.98);
    color: #6fdb70;
    font-size: 17px;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 0px 335px;
}

.ContactForm_button__dDyVN:hover{
    border-radius: 17px;
    border: solid 4px #6fdb70;
    background-color:  #6fdb70;
    color:#ffffff;
    font-size: 17.5px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .ContactForm_container__1b4p4{
        margin-bottom: 30px;
    }
    
    .ContactForm_iconLogo__1m_ze{
        max-width: 60%;
        margin: 60px 0px 50px 40px;
    }
    
    .ContactForm_contactInfo__1ZQql{
        font-size: 12px;
        line-height: 1.5;
    }
    
    .ContactForm_line__2KBC3{
        width: 120px;
        margin: 20px 0px 25px 20px;  
    }
    
    .ContactForm_form__3C9Dp{
        margin: 10px 10px 10px 10px;
    }
    
    .ContactForm_inputText__3w5es{
        font-size: 10px;
    }
    
    .ContactForm_label__4XDTO{
        font-size: 12px;
    }    

    .ContactForm_button__dDyVN{
        border: solid 2px #6fdb70;
        font-size: 12px;
        padding: 5px 5px 5px 5px;
        margin: 0px 0px 0px 70px;
    }
    
    .ContactForm_button__dDyVN:hover{
        border: solid 2px #6fdb70;
        font-size: 12.5px;
    }
}

@media screen and (min-width: 576px) and (max-width:767px) { /* Small Devices */
    .ContactForm_container__1b4p4{
        margin-bottom: 40px;
    }
    
    .ContactForm_iconLogo__1m_ze{
        max-width: 60%;
        margin: 60px 0px 50px 60px;
    }
    
    .ContactForm_contactInfo__1ZQql{
        font-size: 14px;
        line-height: 1.5;
    }
    
    .ContactForm_line__2KBC3{
        width: 150px;
        margin: 20px 0px 25px 45px;  
    }
    
    .ContactForm_form__3C9Dp{
        margin: 10px 10px 10px 10px;
    }
    
    .ContactForm_inputText__3w5es{
        font-size: 14px;
    }
    
    .ContactForm_label__4XDTO{
        font-size: 14px;
    }    

    .ContactForm_button__dDyVN{
        font-size: 14px;
        margin: 0px 0px 0px 140px;
    }
    
    .ContactForm_button__dDyVN:hover{
        font-size: 14.5px;
    } 
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .ContactForm_container__1b4p4{
        margin-bottom: 60px;
    }

    .ContactForm_iconLogo__1m_ze{
        margin: 80px 0px 50px 110px;
    }
    
    .ContactForm_line__2KBC3{
        width: 230px;
        margin: 25px 0px 25px 50px;  
    }
    
    .ContactForm_form__3C9Dp{
        margin: 20px 30px 20px 30px;
    }
    
    .ContactForm_button__dDyVN{
        margin: 0px 0px 0px 180px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .ContactForm_iconLogo__1m_ze{
        margin: 80px 0px 50px 180px;
    }
    
    .ContactForm_line__2KBC3{
        margin: 25px 0px 25px 100px;  
    }

    .ContactForm_button__dDyVN{
        margin: 0px 0px 0px 240px;
    }
}

.Contact_heroH1__3_t8f{
    margin-top: 190px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .Contact_heroH1__3_t8f{
        margin: 90px 5px 0px 5px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .Contact_heroH1__3_t8f{
        margin: 190px 10px 0px 10px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .Contact_heroH1__3_t8f{
        margin-top: 200px;
    }
}

