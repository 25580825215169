.heroImage{
    width: 100%;
    max-height: 700px;
    max-width: 100%; 
    height: auto; 
    margin-top: 100px;
    position: relative;
    object-fit: cover; 
}

.h1 { 
    position: absolute; 
    padding: 0px 200px 0px 200px;
    margin-top: 260px;
    text-align: center;
    font-size: 80px;
    line-height: 1.5em;
    font-weight: bold;
    color: #ffffff;
 }
 
 @media screen and (max-width: 568px) { /* X-Small Devices */
    .heroImage{
        margin-top: 0px;
    }
    
    .h1 { 
        padding: 0px 0px 0px 15px;
        margin-top: 70px;
        text-align: center;
        font-size: 28px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     }
  }

 @media screen and (min-width: 569px) and (max-width: 767px) { /* Small Devices */
    .heroImage{
        margin-top: 0px;
    }
    
    .h1 { 
        padding: 0px 0px 0px 15px;
        margin-top: 110px;
        text-align: center;
        font-size: 28px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     }
  }

  @media screen and (min-width: 768px) and (max-width:992px) { /* Medium to Large Devices */
    .heroImage{
        margin-top: 80px;
    }
    
    .h1 { 
        padding: 0px 0px 0px 10px;
        margin-top: 250px;
        text-align: center;
        font-size: 58px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     } 
  }

  @media screen and (min-width: 993px) and (max-width:1400px) { /* Large to X-Large Devices */
    .heroImage{
        margin-top: 80px;
    }
    
    .h1 { 
        padding: 0px 0px 0px 40px;
        margin-top: 260px;
        text-align: center;
        font-size: 80px;
        line-height: 1.2em;
        font-weight: bold;
        color: #ffffff;
     } 
  }
