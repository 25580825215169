.ZDFImg{
    max-width: 100%;
    height: auto;
    padding: 0px 20px 0px 0px;
    margin: 0px 0px 0px 50px;
    float: left;
}

.description{
    object-fit: contain;
    font-size: 15px;
    line-height: 1.54;
    text-align: justify;
    color: #4a4a4a;
}

.colRightStyle{
    padding: 0px 70px 0px 70px;
}

.colLeftStyle{
    margin: 0px 0px 0px 50px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .ZDFImg{
        max-width: 50%;
        padding: 0px 10px 0px 0px;
        margin: 0px 0px 0px 0px;
    }
    
    .description{
        font-size: 12px;
    }
    
    .colRightStyle{
        padding: 10px 0px 0px 0px;
    }
    
    .colLeftStyle{
        margin: 0px 0px 0px 0px;
    }
}


@media screen and (min-width: 576px) and (max-width:767px) { /* Small Devices */
    .ZDFImg{
        max-width: 50%;
        padding: 0px 10px 0px 0px;
        margin: 0px 0px 0px 0px;
    }
    
    .description{
        font-size: 13px;
    }
    
    .colRightStyle{
        padding: 10px 0px 0px 0px;
    }
    
    .colLeftStyle{
        margin: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .ZDFImg{
        max-width: 50%;
        padding: 0px 20px 0px 0px;
        margin: 0px 0px 20px 0px;
    }

    .description{
        font-size: 14px;
    }
    
    .colRightStyle{
        padding: 0px 20px 0px 20px;
    }
    
    .colLeftStyle{
        margin: 0px 0px 0px 0px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  

}