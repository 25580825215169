.heroH1{
    color: white;
    margin-top: 230px;
}

@media screen and (max-width: 575px) { /* X-Small Devices */
    .heroH1{
        margin: 75px 0px 0px 0px;
    }
}

@media screen and (min-width: 768px) and (max-width:991px) { /* Medium to Large Devices */
    .heroH1{
        margin: 200px 5px 0px 5px;
    }
}

@media screen and (min-width: 992px) and (max-width:1200px) { /* Large to XLarge Devices */  
    .heroH1{
        margin: 225px 0px 0px 0px;
    }
}